/** @jsx jsx */
import { jsx } from 'theme-ui'
import styled from '@emotion/styled'
import { Link } from 'gatsby'

const Container = styled.div`
  border-top: 2px solid #000;
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  ul{
    font-size: ${props => props.theme.fontSizes[1]}px;
    width: 100%;
    @media (min-width: ${props => props.theme.responsive.medium}) {
      width: 100%;
      font-size: ${props => props.theme.fontSizes[5]}px;
    }
  }
  .temp{
    width: 100%;
  }
`

const Item = styled.div`
  display: flex;
  width: 100%;
  max-width: 100%;
  flex-wrap: wrap;
  border-top: 2px solid #000;
  width: 100%;
  padding: 30px;

  @media (min-width: ${props => props.theme.responsive.medium}) {
    padding: 20px 49px 20px 60px;
  }

  &:nth-of-type(1){
    border-top: none;
  }
  &:nth-of-type(4n + 1){
    background: ${props => props.theme.background.gradient.green};
  }
  &:nth-of-type(4n + 2){
    background: ${props => props.theme.background.gradient.red};
  }
  &:nth-of-type(4n + 3){
    background: ${props => props.theme.background.gradient.purple};
  }
  &:nth-of-type(4n + 4){
    background: ${props => props.theme.background.gradient.grey};
  }

  div, a{
    width: 50%;
    flex: 50%;
  }

  a{
    text-align: right;
    opacity: 1 !important;
    span{
      white-space: nowrap;
      border-radius: 999px;
      color: ${props => props.theme.colors.text};
      padding: 2px 10px;
      color: ${props => props.theme.colors.text};
      border: 2px solid ${props => props.theme.colors.text};
      @media (min-width: ${props => props.theme.responsive.large}) {
        display: inline-block;
        &:hover{
          color: ${props => props.theme.colors.background};
          background-color: ${props => props.theme.colors.text};
        }
      }
    }
  }

  @media (min-width: ${props => props.theme.responsive.large}) {
    div, a{
      max-width: 225px;
      width: 100%;
    }
  }
`

const Titel = styled.div`
  @media (min-width: ${props => props.theme.responsive.large}) {
    flex: 1 !important;
    max-width: 100% !important;
  }
`

const Ort = styled.div`
  text-align: right;
  @media (min-width: ${props => props.theme.responsive.large}) {
    text-align: left;
  }
`

const Zeit = styled.div`

`

const ContentLeistungen = props => {
  return (
    <Container>
      <ul>
        {props.transfer.edges.map(({ node }) => (
          <Item key={`jobs-map-${node.contentful_id}`}>
            <Titel>{node.title}</Titel>
            <Ort>{node.ort}</Ort>
            <Zeit>{node.arbeitszeit}</Zeit>
            <Link to={`/jobs/${node.slug}/`}><span>Mehr erfahren</span></Link>
          </Item>
        ))}
      </ul>
    </Container>
  )
}

export default ContentLeistungen
