import React from 'react'
import Layout from '../components/Layout'
import Container from '../components/Container'
import PageTitle from '../components/PageTitle'
import ContainerHero from '../components/ContainerHero'
import ContainerContent from '../components/ContainerContent'
import ContentListe from '../components/ContentJobs'
import SEO from '../components/SEO'
import HeroLeftStandard from '../components/HeroLeftStandard'
import HeroCenterStandard from '../components/HeroCenterStandard'
import { graphql, useStaticQuery } from 'gatsby'
import Newsletter from '../components/NewsletterPopup.js'

const Jobs = () => {
  const data = useStaticQuery(graphql`
    {
      allContentfulJobs(sort: { order: ASC, fields: publishDate }) {
        edges {
          node {
            contentful_id
            internal{
              type
            }
            slug
            title
            ort
            arbeitszeit
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <Newsletter />
      <SEO
        title="Jobs"
        description="Hier finden Sie eine Auswahl von verschiedenen offenen Stellen bei EXTENDIT."
      />
      <Container>
        <ContainerHero nobottom>
          <HeroLeftStandard>
            <PageTitle>Jobs</PageTitle>
          </HeroLeftStandard>
          <HeroCenterStandard>
            Unsere Leistungen bauen immer auf den Säulen Kompetenz,
            Zuverlässigkeit und Transparenz auf. Hier finden Sie eine Auswahl
            von offenen Stellen bei EXTENDIT.
          </HeroCenterStandard>
        </ContainerHero>
        <ContainerContent>
          <ContentListe transfer={data.allContentfulJobs} />
        </ContainerContent>
      </Container>
    </Layout>
  )
}

export default Jobs
